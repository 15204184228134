<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 px-0">
        <div class="card">
          <dx-util-tab-panel :animation-enabled="true" :defer-rendering="true" :show-nav-buttons="true">
            <dx-util-panel-item v-if="hasAdminPermission" title="Inventory" icon="bi bi-stack">
              <template #default>
                <inventory-maintenance />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item v-if="hasAdminPermission" title="Catalog / Product" icon="bi bi-journal-richtext">
              <template #default>
                <catalog-maintenance />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item v-if="hasAdminPermission" title="Product Code" icon="bi bi-upc">
              <template #default>
                <product-code-maintenance />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item v-if="hasAdminPermission" title="Batch" icon="bi bi-collection-fill">
              <template #default>
                <batch-maintenance />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item v-if="hasAdminPermission" title="System" icon="bi bi-gear">
              <template #default>
                <system-maintenance />
              </template>
            </dx-util-panel-item>
          </dx-util-tab-panel>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  components: {
    'inventory-maintenance': () => import('./maintenance/InventoryMaintenance.vue'),
    'catalog-maintenance': () => import('./maintenance/CatalogMaintenance.vue'),
    'product-code-maintenance': () => import('./maintenance/ProductCodeMaintenance.vue'),
    'batch-maintenance': () => import('./maintenance/BatchMaintenance.vue'),
    'system-maintenance': () => import('./maintenance/SystemMaintenance.vue'),
  },
  computed: {
    hasAdminPermission() {
      return this.$can('manage', 'resource_admin')
    },
  },
}
</script>

<style lang="scss">
.dx-tab-selected {
  background-color: #2A8ABA;
}

.dx-tabs {
  border-radius: 4px 4px 0 0;
}
.dx-tab {
  border-radius: 4px 4px 0 0;
}
</style>
